<template>
  <div>
    <HomePageTop/>
    <div class="questionnaireContainer">
      <header></header>
      <footer style="margin-bottom: 30px">
        <div class="questionnaireTitle">{{ questionnaireInfo.survey_title }}</div>
        <div class="questionnaireDesc" v-html="questionnaireInfo.survey_desc"></div>
        <div>
          <div v-for="(item,index) in questionnaireInfo.detail" :key="index">
            <div class="flex">
              <div class="questionnaireTopic">{{ index + 1 }}、{{ item.title }}</div>
              <div v-if="item.type==1" class="multipleChoiceHint">*複数選択可能</div>
            </div>
            <el-checkbox-group v-model="item.checkedList" v-if="item.type==1">
              <el-checkbox :label="optionItem.text" v-for="(optionItem,optionIndex) in item.options" :key="optionIndex">
                {{ optionItem.text }}
                <el-input v-if="optionItem.hasInput==true" style="width: 160px;" v-model="optionItem.inputValue"
                          size="mini"/>
              </el-checkbox>
            </el-checkbox-group>
            <el-radio-group v-model="item.checkedId" v-else-if="item.type==0">
              <el-radio :label="optionItem.id" v-for="(optionItem,optionIndex) in item.options" :key="optionIndex">
                {{ optionItem.text }}
                <el-input v-if="optionItem.hasInput==true" style="width: 160px;" v-model="optionItem.inputValue"
                          size="mini"/>
              </el-radio>
            </el-radio-group>
            <el-input v-model="item.inputValue" v-else/>
            <div class="requiredText">必須</div>
          </div>
        </div>
        <div class="submitBtnContainer" v-if="JSON.stringify(questionnaireInfo)!=='{}'">
          <div @click="questionnaireSubmit">{{ $fanyi('提交') }}</div>
        </div>
      </footer>
    </div>
    <FootVue/>
  </div>
</template>

<script>
import FootVue from "../../components/foot/Foot.vue";
import HomePageTop from "../../components/head/HomePageTop.vue";

export default {
  components: {
    FootVue,
    HomePageTop
  },
  data() {
    return {
      questionnaireInfo: {}
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$api.questionnaireDetail({
        questionnaire_id: this.$route.query.id,
      }).then((res) => {
        res.data.detail.forEach((item) => {
          if (item.type == 0) {
            item.checkedId = '';
          } else if (item.type == 1) {
            item.checkedList = [];
          } else {
            item.inputValue = '';
          }
          item.options.forEach((optionsItem, optionIndex) => {
            optionsItem.inputValue = '';
            optionsItem.id = optionIndex;
          })
        })
        this.questionnaireInfo = res.data;
      });
    },
    //试卷提交
    questionnaireSubmit() {
      let status = false;
      let arr = [];
      let data = {
        questionnaire_id: this.$route.query.id,
        questionnaire_detail: arr
      };
      for (let i = 0; i < this.questionnaireInfo.detail.length; i++) {
        if ((this.questionnaireInfo.detail[i].type == 1 && this.questionnaireInfo.detail[i].checkedList.length === 0) || (this.questionnaireInfo.detail[i].type == 0 && this.questionnaireInfo.detail[i].checkedId === '') || this.questionnaireInfo.detail[i].type == 2 && this.questionnaireInfo.detail[i].inputValue === '') {
          status = true
          break;
        } else {
          if (this.questionnaireInfo.detail[i].type == 1) {
            let checkedList = [];
            //循环选项数组和选中的数组
            this.questionnaireInfo.detail[i].options.forEach((optionsItem) => {
              this.questionnaireInfo.detail[i].checkedList.forEach((checkedItem) => {
                //判断可选项数组的文本与已选中的文本
                if (checkedItem === optionsItem.text) {
                  if (optionsItem.hasInput === true) {
                    checkedList.push(optionsItem.inputValue)
                  } else {
                    checkedList.push(optionsItem.text)
                  }
                }
              })
            })
            arr.push({id: this.questionnaireInfo.detail[i].id, answer: checkedList})
          } else if (this.questionnaireInfo.detail[i].type == 0) {
            arr.push({
              id: this.questionnaireInfo.detail[i].id,
              //判断单选框是否支持输入，支持输入就追加输入的值，否则返回文本
              answer: this.questionnaireInfo.detail[i].options[this.questionnaireInfo.detail[i].checkedId].hasInput == false ? [this.questionnaireInfo.detail[i].options[this.questionnaireInfo.detail[i].checkedId].text] : [this.questionnaireInfo.detail[i].options[this.questionnaireInfo.detail[i].checkedId].inputValue]
            })
          } else {
            arr.push({id: this.questionnaireInfo.detail[i].id, answer: [this.questionnaireInfo.detail[i].inputValue]})
          }
        }
      }
      if (status === true) {
        this.$message.error(this.$fanyi('缺少必填项'))
        return
      }
      data.questionnaire_detail = JSON.stringify(data.questionnaire_detail);
      this.$api.questionnaireSubmit(data).then((res) => {
        if (res.code != 0) return this.$message.error(this.$fanyi(res.msg))
        this.$message.success(this.$fanyi(res.msg));
        window.close();
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.questionnaireContainer {
  width: 1000px;
  margin: -21px auto 0;

  header {
    background: url("../../assets/questionnaireBanner.png") no-repeat;
    width: 1000px;
    height: 240px;
    background-size: 100% 100%;
  }

  footer {
    padding: 80px 50px 60px;
    background: #fff;

    .questionnaireTitle {
      color: #333333;
      font-weight: bold;
      font-size: 30px;
      text-align: center;
      margin-bottom: 50px;
    }

    .questionnaireDesc {
      color: #333333;
      font-size: 18px;
      margin-bottom: 50px;
      white-space: pre-wrap;
    }

    .questionnaireTopic {
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 20px;
      white-space: pre-wrap;
    }

    /deep/ .el-checkbox__original {
      width: 0 !important;
      height: 0 !important;
    }

    .requiredText {
      margin: 20px 0 40px;
      color: #FF0000;
    }

    .multipleChoiceHint {
      color: #FF0000;
      font-size: 16px;
    }

    .submitBtnContainer {
      display: flex;
      justify-content: center;

      div {
        width: 68px;
        height: 40px;
        background: #B4272C;
        border-radius: 6px;
        cursor: pointer;
        line-height: 40px;
        text-align: center;
        color: #FFFFFF;
        font-weight: bold;
      }
    }
  }
}
</style>
